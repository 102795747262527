/* eslint-disable @typescript-eslint/no-unused-vars */
import { proxy } from 'valtio';
import type { Response, Plan, Service, Devis, Country, Transaction, Subscription, Client } from '../../models';
import { getDataWithToken,getData, postDataWithToken } from '../../helpers/http';

class CoreStore {
    plans: Plan[] = [];
    services: Service[] = [];
    devis: Devis[] = [];
    plan: Plan = {} as Plan;
    service: Service = {} as Service;
    countries: Country[] = [];
    transactions: Transaction[] = [];
    subscriptions: Subscription[] = [];
    client: Client = {} as Client;

    constructor() {

        //get data from storage
        this.services = this.getDataFromStorage('services')
        this.plans = this.getDataFromStorage('plans')
        this.countries = this.getDataFromStorage('countries')
        this.client = this.getDataFromStorage('client')
        this.devis = this.getDataFromStorage('devis')
        this.subscriptions = this.getDataFromStorage('subscriptions')
        this.transactions = this.getDataFromStorage('transactions')


        this.getPlans = this.getPlans.bind(this);
        this.getServices = this.getServices.bind(this);
        this.getDevis = this.getDevis.bind(this);
        this.getPlan = this.getPlan.bind(this);
        this.createPaymentIntent = this.createPaymentIntent.bind(this);
        this.checkPaymentIntent = this.checkPaymentIntent.bind(this);
        this.getCountries = this.getCountries.bind(this)
        this.getClientData = this.getClientData.bind(this)
    }

    private setData(key: string, data: any){
        localStorage.setItem(key, JSON.stringify(data));
        Object.assign((this as any)[key], data);
    }

    private getDataFromStorage(key: string){
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data)
        }
    }

    async getPlans(): Promise<Response> {
        try {
            const response = await getDataWithToken('plans');
            if (response.success) {
                this.plans = response.result;
                localStorage.setItem('plans', JSON.stringify(this.plans));
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    getPlan(id: number): Plan | undefined {
        const plan = this.plans.find((p) => p.id === id);
        console.log('=============plan item=======================');
        console.log(plan);
        console.log('====================================');
        return plan;
    }
    

    async getServices(): Promise<Response> {
        try {
            const response = await getDataWithToken('services');
            if (response.success) {
                this.services = response.result;
                localStorage.setItem('services', JSON.stringify(this.services));
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getDevis(): Promise<Response> {
        try {
            const response = await getDataWithToken('devis');
            if (response.success) {
                this.devis = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getCountries(): Promise<Response> {
        try {
            const response = await getData('countries');
            if (response.success) {
                this.countries = response.result;
                localStorage.setItem('countries', JSON.stringify(this.countries));
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            }
        }
    }

    async createPaymentIntent(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('payments/create-intent', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async checkPaymentIntent(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('payments/check-payment', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async requestDevis(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('devis-request/add', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async getClientData(): Promise<Response> {
        try {
            const response = await postDataWithToken('client');
            if (response.success) {
                let result = response.result;
                this.client = result.client;
                this.devis = result.devis;
                this.subscriptions = result.subscriptions;
                this.transactions = result.transactions;

                //set data
                this.setData('client',this.client);
                this.setData('devis',this.devis);
                this.setData('subscriptions',this.subscriptions)
                this.setData('transactions',this.transactions)
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

}

const coreStore = proxy(new CoreStore());

export default coreStore