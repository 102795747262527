/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import store from '../../../stores/store';
import { useSnapshot } from 'valtio';
const Topbar = () => {
    const navigate = useNavigate();

    const snap = useSnapshot(store);
    const user = snap.auth.user;
    const onLogout = async() => {
        const res = await snap.auth.logout();
        if (res.success) {
            localStorage.clear();
            toast.success("Vous avez bien été déconnecté avec succès");
            setTimeout(() => {
                navigate('/');
            }, 3500);
        } else {
            toast.error(res.message);

        }
    }

    return (
        <>
            {/* [ Header Topbar ] start */}
            <header className="pc-header">
                <div className="header-wrapper"> {/* [Mobile Media Block] start */}
                    <div className="me-auto pc-mob-drp">
                        <ul className="list-unstyled">
                            <li className="pc-h-item header-mobile-collapse">
                                <a href="#" className="pc-head-link head-link-secondary ms-0" id="sidebar-hide">
                                    <i className="ti ti-menu-2" />
                                </a>
                            </li>
                            <li className="pc-h-item pc-sidebar-popup">
                                <a href="#" className="pc-head-link head-link-secondary ms-0" id="mobile-collapse">
                                    <i className="ti ti-menu-2" />
                                </a>
                            </li>
                            <li className="dropdown pc-h-item d-inline-flex d-md-none">
                                <a className="pc-head-link head-link-secondary dropdown-toggle arrow-none m-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-search" />
                                </a>
                                <div className="dropdown-menu pc-h-dropdown drp-search">
                                    <form className="px-3">
                                        <div className="form-group mb-0 d-flex align-items-center">
                                            <i data-feather="search" className="ti ti-search" />
                                            <input type="search" className="form-control border-0 shadow-none" placeholder="Search here. . ." />
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li className="pc-h-item d-none d-md-inline-flex">
                                <form className="header-search">
                                    <i data-feather="search"  />
                                    <input type="search" className="form-control" placeholder="Search here. . ." />
                                    <button className="btn btn-light-secondary btn-search"><i className="ti ti-adjustments-horizontal" /></button>
                                </form>
                            </li>
                        </ul>
                    </div>
                    {/* [Mobile Media Block end] */}
                    <div className="ms-auto">
                        <ul className="list-unstyled">
                            
                            <li className="dropdown pc-h-item">
                                <a className="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-language" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                                    <a href="#!" className="dropdown-item">
                                        <span>English <small>(UK)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>français <small>(French)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>Română <small>(Romanian)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>中国人 <small>(Chinese)</small></span>
                                    </a>
                                </div>
                            </li>
                            <li className="dropdown pc-h-item">
                                <a className="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-bell" />
                                </a>
                                <div className="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                                    <div className="dropdown-header">
                                        <a href="#!" className="link-primary float-end text-decoration-underline">Marquée comme lu</a>
                                        <h5>Toutes les notifications <span className="badge bg-warning rounded-pill ms-1">01</span></h5>
                                    </div>
                                    <div className="dropdown-header px-0 text-wrap header-notification-scroll position-relative" style={{ maxHeight: 'calc(100vh - 215px)' }}>
                                        <div className="list-group list-group-flush w-100">
                                            <div className="list-group-item">
                                                <select className="form-select">
                                                    <option value="all">Toutes les notifications</option>
                                                    <option value="new">Nouveau</option>
                                                    <option value="unread">Non ouverts</option>
                                                    <option value="other">Autres</option>
                                                </select>
                                            </div>
                                            <div className="list-group-item list-group-item-action">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-1">
                                                        <span className="float-end text-muted">2 min ago</span>
                                                        <h5>John Doe</h5>
                                                        <p className="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                                                        <div className="badge rounded-pill bg-light-danger">Unread</div>
                                                        <div className="badge rounded-pill bg-light-warning">New</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="text-center py-2">
                                        <a href="#!" className="link-primary">Marquée tout comme lu</a>
                                    </div>
                                </div>
                            </li>
                            <li className="dropdown pc-h-item header-user-profile">
                                <a className="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
                                    <span>
                                        <i className="ti ti-settings" />
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                                    <div className="dropdown-header">
                                        <h4>Bonjour, <span className="small text-muted"> {user.name}</span></h4>
                                        <p className="text-muted">{user?.role?.name}</p>
                                        <hr />
                                        <div className="profile-notification-scroll position-relative" style={{ maxHeight: 'calc(100vh - 280px)' }}>
                                            
                                            <a href="#" className="dropdown-item">
                                                <i className="ti ti-settings" />
                                                <span>Paramètres du compte</span>
                                            </a>
                                            <a href="#" className="dropdown-item">
                                                <i className="ti ti-user" />
                                                <span>Mon Profile</span>
                                            </a>
                                            <a href="#" className="dropdown-item" onClick={() => onLogout()}>
                                                <i className="ti ti-logout" />
                                                <span>Déconnexion</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* [ Header ] end */}

        </>
    )
}

export default Topbar
