import React from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
const VerifyOTP = () => {
    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                        <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="d-flex justify-content-center">
                                        <div className="auth-header">
                                            <h2 className="text-secondary"><b>Entrez votre code de vérification </b></h2>
                                            <h4 className="mt-2">
                                                envoyé à votre adresse email
                                            </h4>
                                            <p className="f-16 mt-2">Nous avons envoyé un code à l'adresse john.****@test.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col">
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="d-grid mt-4">
                                    <button type="button" className="btn btn-primary p-2">VALIDER</button>
                                </div>
                                <div className="d-flex mt-1 justify-content-between mt-4">
                                    <p className="text-muted">Aucun code reçu ? Veuillez verifier votre spam, ou</p>
                                    <p className="text-primary">Renvoyez un code</p>
                                </div>
                                <hr />
                                <h5 className="d-flex justify-content-center">Vous n'avez pas reçu de mail? Veuillez vérifiez votre spam, ou</h5>
                                <div className="d-grid mt-4">
                                    <button type="button" className="btn btn-outline-secondary bg-light text-secondary p-2">Renvoyé le code</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AuthSlider />
                </div>
            </div>

        </>
    )
}

export default VerifyOTP
