import React from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { Link } from 'react-router-dom';
const ForgotPassword = () => {
    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                        <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="d-flex justify-content-center">
                                        <div className="auth-header text-center">
                                            <h2 className="text-secondary"><b>Mot de passe oublié?</b></h2>
                                            <p className="f-16 mt-2">
                                                Entrez votre adresse email et nous vous enverrons un code OTP pour reinitialiser votre mot de passe
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="Email Address / Username" />
                                    <label htmlFor="floatingInput">Email Address / Username</label>
                                </div>
                                <div className="d-grid mt-3">
                                    <button type="button" className="btn btn-secondary">Envoyé</button>
                                </div>
                                <hr />
                                <Link to={'/'}>
                                    <h5 className="d-flex justify-content-center">Vous avez déjà un compte? connectez-vous ici</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <AuthSlider />
                </div>
            </div>

        </>
    )
}

export default ForgotPassword
